import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export const About = () => {
  return (
    <div className="feature feature-3">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../../components/images/adminlastpro.png')}
            alt="#"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 hero__lead">
            Give your admin website/apps a refreshing look
          </h2>
          <p className="feature__text">
            The Contrast Bootstrap admin Pro template was built from the ground up with the end users in mind.
            We made sure to make the interface very intuitive. Get started using the Contrast admin
            Pro dashboard today for your various projects.
          </p>

          <div className="feature__buttons">
            <AnchorLink href="#pricing-plans" className="btn1 btn__inverse">
              <span className="btn__text">Get Admin Pro </span>
            </AnchorLink>
            <Link to="/docs/contrast/javascript/index" className="btn1 btn-white">
              <span className="btn__text">Contrast Pro Doc</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
