import React from 'react';
import { Link } from 'gatsby';

import styled from "../../../components/images/styledc.png";
import AnchorLink from "react-anchor-link-smooth-scroll";


export const Hero = () => {
  return (
    <div className="hero">
      <div className="feature__wrap">
        <div className="feature__left">
          <h2 className="feature__lead feature__lead--1 hero__lead">
            Contrast Bootstrap Admin Dashboard (Pro)
          </h2>
          <p className="feature__text">
            Beautifully designed, responsive bootstrap admin dashboards to power your admin websites
            and apps. Built with today's standards and developed with the best tools
          </p>

          <div className="feature__buttons">
            <AnchorLink className="btn1 btn__inverse" href="#pricing-plans">
              <span className="btn__text">Get Admin Pro</span>
            </AnchorLink>

            <a
              className="btn1 btn-white"
              target="_blank"
              rel="noreferrer"
              href="https://cdbootstrap-demo.netlify.app"
            >
              <span className="btn__icon">
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0457 9.82757L8.34869 6.14804C8.03574 5.99838 7.68104 6.04213 7.44706 6.25908C7.21308 6.47602 7.14486 6.82459 7.27377 7.14506L8.51447 10.2331L7.56331 13.2171C7.46745 13.5179 7.56066 13.8628 7.80049 14.1026C7.8076 14.1097 7.81479 14.1168 7.82212 14.1237C8.07788 14.3639 8.43819 14.4416 8.73836 14.3212L16.1186 11.3601C16.4159 11.2407 16.5977 10.9495 16.5819 10.6176C16.566 10.2857 16.3555 9.97559 16.0457 9.82757ZM9.74123 12.054L10.2056 10.5971C10.2632 10.4165 10.2536 10.2154 10.1784 10.0285L9.57293 8.52147L13.6673 10.4788L9.74123 12.054Z"
                    fill="#1F1F1F"
                  />
                  <path
                    d="M10.9994 0C4.93431 0.00010632 0 4.48605 0 9.99989C0 15.5138 4.93431 19.9999 10.9995 20C17.0651 19.9999 21.9998 15.5139 21.9998 10C21.9998 4.48594 17.065 0 10.9994 0ZM10.9994 18.4051C5.90162 18.405 1.75416 14.6345 1.75416 9.99979C1.75416 5.36531 5.9015 1.59479 10.9994 1.59469C16.0977 1.59469 20.2454 5.36521 20.2454 9.99989C20.2455 14.6345 16.0977 18.405 10.9994 18.4051Z"
                    fill="#1F1F1F"
                  />
                </svg>
              </span>
              <span className="btn__text">Preview</span>
            </a>
          </div>
        </div>
        <div className="feature__right">
          <img
            src={require('../../../components/images/adminheropro.png')}
            alt="#"
            className="feature__img"
          />
        </div>
      </div>

      <div className="hero__icons">
        <p className="hero__icon hero__icon--facebook">
          <svg width="53" height="53" viewBox="0 0 53 53" fill="none">
            <path
              d="M33.028 29.505a5.504 5.504 0 00-3.809-1.21h-9.402v9.621h9.556a5.416 5.416 0 003.655-1.277 4.755 4.755 0 001.454-3.61 4.602 4.602 0 00-1.454-3.524zm-1.32-6.209a3.963 3.963 0 001.32-3.17 4.007 4.007 0 00-1.167-3.282 5.152 5.152 0 00-3.236-.946h-8.808v8.499h8.808a4.844 4.844 0 003.17-1.101h-.088zM44.037 0H8.808A8.808 8.808 0 000 8.808v35.23a8.808 8.808 0 008.808 8.807h35.23a8.808 8.808 0 008.807-8.807V8.808A8.808 8.808 0 0044.038 0zm-4.822 37.102a6.605 6.605 0 01-2.026 2.73 9.952 9.952 0 01-3.545 1.806c-1.665.49-3.395.72-5.13.682H14.356V11.494H29.33c2.35-.117 4.674.549 6.606 1.893a6.715 6.715 0 012.51 5.747 6.804 6.804 0 01-1.013 3.986 7.42 7.42 0 01-3.259 2.576 6.718 6.718 0 014.404 2.686 8.983 8.983 0 011.475 5.24 8.631 8.631 0 01-.836 3.48z"
              fill="#828282"
            />
          </svg>
        </p>
        <p className="hero__icon hero__icon--html">
          <svg width="38" height="54" viewBox="0 0 38 54" fill="none">
            <path
              d="M6.064 5.28H8.25v2.393h2.389V.528h-2.39v2.36H6.065V.529H3.676v7.145h2.388V5.28zM13.777 7.673h2.388V2.899h2.102V.528h-6.591v2.37h2.101v4.775zM21.65 4.133l1.646 2.54h.04l1.642-2.54v3.54h2.38V.528h-2.492l-1.53 2.514L21.805.528h-2.49v7.145h2.335v-3.54zM34.291 5.313h-3.358V.528h-2.388v7.145h5.746v-2.36zM.234 10.808l3.416 38.31 15.332 4.255 15.345-4.253 3.422-38.312H.234zM30.34 23.337H12.358l.43 4.811H29.91l-1.288 14.43-9.632 2.668-.006.002-9.626-2.67-.672-7.548h4.716l.35 3.915 5.233 1.414.006-.002 5.239-1.414.545-6.094H8.492L7.222 18.64h23.536l-.42 4.697z"
              fill="#828282"
            />
          </svg>
        </p>
        <p className="hero__icon hero__icon--css3">   
          <svg width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4127 8.52164C28.2564 8.34938 28.0347 8.25114 27.8021 8.25098H1.39967C0.943999 8.24897 0.572947 8.6167 0.570936 9.07237C0.570781 9.09952 0.572019 9.1266 0.574572 9.15359L3.0498 35.556C3.08174 35.8947 3.31828 36.1791 3.64548 36.2722L15.1965 39.5725C15.2711 39.5937 15.3484 39.6042 15.4259 39.6038C15.5143 39.6038 15.6024 39.5898 15.6866 39.5626L25.5875 36.2623C25.8981 36.1589 26.1181 35.8819 26.1485 35.556L28.6238 9.15359C28.6455 8.9227 28.5688 8.69328 28.4127 8.52164Z" fill="#828282"/>
            <path d="M15.4266 33.0038C15.3501 33.0033 15.274 32.9922 15.2005 32.9707L9.42497 31.3206C9.14148 31.2399 8.92258 31.0141 8.85072 30.7282L8.02562 27.4278C7.90511 26.9884 8.16369 26.5344 8.60312 26.4139C9.04255 26.2934 9.49652 26.552 9.61704 26.9914C9.62044 27.0037 9.62346 27.016 9.62624 27.0285L10.3358 29.8667L15.4265 31.3221L20.4429 29.8881L21.928 16.5037H7.17569C6.72001 16.5037 6.35059 16.1343 6.35059 15.6786C6.35059 15.223 6.72001 14.8535 7.17569 14.8535H22.8522C23.3078 14.8507 23.6794 15.2179 23.6822 15.6736C23.6824 15.7056 23.6807 15.7376 23.6772 15.7694L22.0271 30.6207C21.9896 30.9533 21.7549 31.2304 21.433 31.3221L15.6574 32.9722C15.5825 32.9937 15.5047 33.0043 15.4266 33.0038Z" fill="#FAFAFA"/>
            <path d="M22.0265 23.1024H8.00027C7.5446 23.1024 7.17517 22.7329 7.17517 22.2772C7.17517 21.8216 7.5446 21.4521 8.00027 21.4521H22.0265C22.4822 21.4521 22.8516 21.8216 22.8516 22.2772C22.8516 22.7329 22.4822 23.1024 22.0265 23.1024Z" fill="#FAFAFA"/>
            <path d="M9.65058 4.95143H6.35025C5.89457 4.95143 5.52515 4.582 5.52515 4.12633V0.826078C5.52522 0.370404 5.89457 0.000976562 6.35025 0.000976562H9.65058C10.1063 0.000976562 10.4757 0.370404 10.4757 0.826078C10.4757 1.28175 10.1063 1.65118 9.65058 1.65118H7.17535V3.30131H9.65058C10.1063 3.30131 10.4757 3.67073 10.4757 4.12641C10.4757 4.58208 10.1063 4.95143 9.65058 4.95143Z" fill="#828282"/>
            <path d="M16.2518 4.95053H12.9514C12.4957 4.95053 12.1263 4.58111 12.1263 4.12543C12.1263 3.66976 12.4957 3.30033 12.9514 3.30033H14.26L12.3673 1.40926C12.0446 1.08748 12.044 0.565049 12.3658 0.242419C12.521 0.0868663 12.7317 -0.000385474 12.9514 1.28029e-06H16.2518C16.7074 1.28029e-06 17.0769 0.369429 17.0769 0.825103C17.0769 1.28078 16.7074 1.6502 16.2518 1.6502H14.9432L16.8343 3.54128C17.1569 3.86306 17.1575 4.38549 16.8357 4.70812C16.681 4.86328 16.4709 4.95046 16.2518 4.95053Z" fill="#828282"/>
            <path d="M22.8522 4.95046H19.5519C19.0962 4.95046 18.7268 4.58103 18.7268 4.12535C18.7268 3.66968 19.0962 3.30025 19.5519 3.30025H20.8604L18.9677 1.40926C18.6451 1.08748 18.6445 0.565049 18.9663 0.242419C19.1214 0.0868663 19.3322 -0.000385474 19.5519 1.28029e-06H22.8522C23.3079 1.28029e-06 23.6773 0.369429 23.6773 0.825103C23.6773 1.28078 23.3079 1.6502 22.8522 1.6502H21.5437L23.4347 3.54128C23.7574 3.86306 23.758 4.38549 23.4362 4.70812C23.2814 4.8632 23.0713 4.95038 22.8522 4.95046Z" fill="#828282"/>
          </svg>
        </p>
        <p className="hero__icon hero__icon--js">
          <svg width="50px" height="50px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" fill="#797979">
            <path d="M 43.335938 4 L 6.667969 4 C 5.195313 4 4 5.195313 4 6.667969 L 4 43.332031 C 4 44.804688 5.195313 46 6.667969 46 L 43.332031 46 C 44.804688 46 46 44.804688 46 43.335938 L 46 6.667969 C 46 5.195313 44.804688 4 43.335938 4 Z M 27 36.183594 C 27 40.179688 24.65625 42 21.234375 42 C 18.140625 42 15.910156 39.925781 15 38 L 18.144531 36.097656 C 18.75 37.171875 19.671875 38 21 38 C 22.269531 38 23 37.503906 23 35.574219 L 23 23 L 27 23 Z M 35.675781 42 C 32.132813 42 30.121094 40.214844 29 38 L 32 36 C 32.816406 37.335938 33.707031 38.613281 35.589844 38.613281 C 37.171875 38.613281 38 37.824219 38 36.730469 C 38 35.425781 37.140625 34.960938 35.402344 34.199219 L 34.449219 33.789063 C 31.695313 32.617188 29.863281 31.148438 29.863281 28.039063 C 29.863281 25.179688 32.046875 23 35.453125 23 C 37.878906 23 39.621094 23.84375 40.878906 26.054688 L 37.910156 27.964844 C 37.253906 26.789063 36.550781 26.328125 35.453125 26.328125 C 34.335938 26.328125 33.628906 27.039063 33.628906 27.964844 C 33.628906 29.109375 34.335938 29.570313 35.972656 30.28125 L 36.925781 30.691406 C 40.171875 32.078125 42 33.496094 42 36.683594 C 42 40.117188 39.300781 42 35.675781 42 Z"/>
          </svg>
        </p>
      </div>
        
      <div className="feature__buttons">
        <Link to="/product/bootstrap-contrast/" className="btn1 btn-white">
          <span className="btn__text">Contrast</span>
        </Link>
        <Link to="/product/bootstrap-contrast-pro/" className="btn1 btn__inverse">
          <span className="btn__text">Contrast Pro</span>
        </Link>
        <Link to="/product/bootstrap-admin-contrast/" className="btn1 btn-white">
          <span className="btn__text">Contrast Admin Template</span>
        </Link>
        <Link to="/product/bootstrap-contrast-pro/" className="btn1 btn__inverse">
          <span className="btn__text">Get Admin Pro Template</span>
        </Link>
      </div>
    </div>
  );
};
